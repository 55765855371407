"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdvSearch = void 0;
var aurelia_event_aggregator_1 = require("aurelia-event-aggregator");
var aurelia_framework_1 = require("aurelia-framework");
var aurelia_dialog_1 = require("aurelia-dialog");
var AdvSearch = /** @class */ (function () {
    function AdvSearch(EventAggregator, DialogController) {
        this.checkCount = 0;
        this.btnStatus = false;
        this.ea = EventAggregator;
        this.controller = DialogController;
    }
    AdvSearch.prototype.activate = function (model) {
        this.filters = model;
        for (var filter in this.filters) {
            for (var i in this.filters[filter]) {
                if (this.filters[filter][i].checked == 1) {
                    this.checkCount++;
                    this.btnStatus = true;
                }
            }
        }
    };
    AdvSearch.prototype.publishFilter = function () {
        this.filters['sidebar'] = '';
        this.filters['search'] = '';
        this.ea.publish("filters");
        this.controller.ok();
    };
    AdvSearch.prototype.enableSubmit = function (check) {
        this.checkCount = check ? ++this.checkCount : --this.checkCount;
        this.btnStatus = this.checkCount > 0 ? true : false;
    };
    AdvSearch.prototype.clearFilters = function (filters) {
        for (var filter in filters) {
            for (var i in filters[filter]) {
                filters[filter][i].checked = false;
            }
        }
    };
    AdvSearch = __decorate([
        (aurelia_framework_1.autoinject),
        __metadata("design:paramtypes", [aurelia_event_aggregator_1.EventAggregator, aurelia_dialog_1.DialogController])
    ], AdvSearch);
    return AdvSearch;
}());
exports.AdvSearch = AdvSearch;
