"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SummarizeEndorsementValueConverter = void 0;
var SummarizeEndorsementValueConverter = /** @class */ (function () {
    function SummarizeEndorsementValueConverter() {
    }
    SummarizeEndorsementValueConverter.prototype.toView = function (value) {
        if (value.deprecate == 1) {
            return "Deprecate";
        }
        if (value.endorse == 1) {
            return "Endorsed";
        }
        else if (value.public == 1) {
            return "Public";
        }
        else {
            return "Return";
        }
        return 'yes';
    };
    return SummarizeEndorsementValueConverter;
}());
exports.SummarizeEndorsementValueConverter = SummarizeEndorsementValueConverter;
