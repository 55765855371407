"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateButtons = void 0;
var aurelia_event_aggregator_1 = require("aurelia-event-aggregator");
var aurelia_framework_1 = require("aurelia-framework");
var aurelia_dialog_1 = require("aurelia-dialog");
var download_prompt_1 = require("../../modals/download-prompt/download-prompt");
var TemplateButtons = /** @class */ (function () {
    function TemplateButtons(dialogService, EventAggregator) {
        this.dialogService = dialogService;
        this.searchText = '';
        this.dialogService = dialogService;
        this.ea = EventAggregator;
    }
    TemplateButtons.prototype.openDownload = function () {
        var _this = this;
        this.dialogService.open({ viewModel: download_prompt_1.DownloadPrompt, model: this.download, lock: false }).whenClosed(function (response) {
            if (!response.wasCancelled) {
                _this.download = [];
            }
        });
    };
    __decorate([
        aurelia_framework_1.bindable,
        __metadata("design:type", Object)
    ], TemplateButtons.prototype, "download", void 0);
    TemplateButtons = __decorate([
        (aurelia_framework_1.autoinject),
        __metadata("design:paramtypes", [aurelia_dialog_1.DialogService, aurelia_event_aggregator_1.EventAggregator])
    ], TemplateButtons);
    return TemplateButtons;
}());
exports.TemplateButtons = TemplateButtons;
