"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pagination = void 0;
var aurelia_framework_1 = require("aurelia-framework");
var aurelia_store_1 = require("aurelia-store");
var operators_1 = require("rxjs/operators");
var Pagination = /** @class */ (function () {
    function Pagination(bindingengine) {
        this.bindingengine = bindingengine;
        this.pageArr = [];
        //var to stop "No templates returned" message to appear before table data is ready
        this.done = false;
    }
    Pagination.prototype.bind = function () {
        this.pagination();
    };
    Pagination.prototype.attached = function () {
        var _this = this;
        this.subscription = this.bindingengine
            .propertyObserver(this, 'templatecount')
            .subscribe(function (newValue, oldValue) {
            _this.pagination();
        });
        this.subscription = this.bindingengine
            .propertyObserver(this, 'pagenum')
            .subscribe(function (newValue, oldValue) {
            _this.pagination();
        });
    };
    Pagination.prototype.detached = function () {
        this.subscription.dispose();
    };
    // this is one way to reference a parent's props from within a child <compose>
    // element's view-model
    // other way: https://github.com/aurelia/framework/issues/242
    Pagination.prototype.created = function (owningView, myView) {
    };
    //math for setting pages
    Pagination.prototype.pagination = function () {
        this.totalPages = Math.ceil(this.templatecount / this.pagesize);
        var i = 1;
        var j = this.totalPages;
        if (this.totalPages > 10) {
            if (this.pagenum > 5) {
                i = this.pagenum - 4;
            }
            if (this.pagenum < j - 5) {
                j = 9 + i;
            }
            else {
                i = j - 9;
            }
        }
        this.pageArr = [];
        while (i <= j) {
            this.pageArr.push(i);
            i++;
        }
    };
    Pagination.prototype.pageNumChange = function (page) {
        this.pagenum = page;
    };
    Pagination.prototype.nextPage = function (direction) {
        this.pagenum = direction == 'next' ? ++this.pagenum : --this.pagenum;
    };
    __decorate([
        aurelia_framework_1.bindable,
        __metadata("design:type", Number)
    ], Pagination.prototype, "templatecount", void 0);
    __decorate([
        aurelia_framework_1.bindable,
        __metadata("design:type", Number)
    ], Pagination.prototype, "pagesize", void 0);
    __decorate([
        aurelia_framework_1.bindable,
        __metadata("design:type", Number)
    ], Pagination.prototype, "pagenum", void 0);
    Pagination = __decorate([
        (aurelia_framework_1.autoinject),
        (0, aurelia_store_1.connectTo)(function (store) { return store.state.pipe((0, operators_1.pluck)('template')); }),
        __metadata("design:paramtypes", [aurelia_framework_1.BindingEngine])
    ], Pagination);
    return Pagination;
}());
exports.Pagination = Pagination;
