"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormatArrayStringValueConverter = void 0;
var FormatArrayStringValueConverter = /** @class */ (function () {
    function FormatArrayStringValueConverter() {
    }
    FormatArrayStringValueConverter.prototype.toView = function (value, delimiter) {
        if (delimiter === void 0) { delimiter = ", "; }
        //add space after commas from string genereated from array
        if (value) {
            value = value.replace(/,/g, delimiter);
        }
        return value;
    };
    return FormatArrayStringValueConverter;
}());
exports.FormatArrayStringValueConverter = FormatArrayStringValueConverter;
