"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialState = void 0;
exports.initialState = {
    auth: {
        loggedIn: false,
        reviewer: false,
        chair: false,
        userId: '',
        name: '',
        editor: false
    },
    template: {
        tablename: 'home',
        focusedtemplate: null,
        focusedversion: null,
        focusededit: null
    },
    seen_tour: false
};
