"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AboutUs = void 0;
var filter_state_1 = require("../../state/filter-state");
var aurelia_framework_1 = require("aurelia-framework");
var config_1 = require("../../config");
var members_service_1 = require("../../services/members-service");
var AboutUs = /** @class */ (function () {
    function AboutUs(FilterState, MemberService) {
        // members
        this.memberChairman = [];
        this.memberRArray = [];
        this.boardLiaison = [];
        this.structuredReChair = [];
        // subcommittee
        this.subComChairman = [];
        this.memberArray = [];
        this.memberResident = [];
        this.vendorLiaison = [];
        this.cdeSterringCoChair = [];
        this.radLexSterringChair = [];
        this.radLexSterringViceChair = [];
        this.subComBoardLiaison = [];
        this.staffContact = [];
        this.membersList = [];
        this.subMembersList = [];
        this.filterstate = FilterState;
        this.filterstate.home = false;
        this.memberService = MemberService;
    }
    // array of tlap board member data for repeat for
    AboutUs.prototype.getCommitteeMembers = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, memberData, i, JSON_1, name, role;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.memberService.getCommitteeMembers()];
                    case 1:
                        _a.member = _b.sent();
                        memberData = this.member;
                        for (i = 0; i < memberData.length; i++) {
                            JSON_1 = null;
                            name = memberData[i].CommitteeMemberLabelName;
                            role = memberData[i].PositionCodeDescriptionDisplay;
                            JSON_1 = {
                                name: name,
                                role: role,
                            };
                            // loop through memberData name:role and put each Member by their array
                            if (JSON_1.role === "Chairman") {
                                this.memberChairman.push(JSON_1);
                            }
                            else if (JSON_1.role === "Board Liaison") {
                                this.boardLiaison.push(JSON_1);
                            }
                            else if (JSON_1.role === "Structured Reporting Chairman") {
                                this.structuredReChair.push(JSON_1);
                            }
                            else {
                                this.memberRArray.push(JSON_1);
                            }
                        }
                        // concat/sort member role arrays and push to front-end memberList array
                        this.membersList = this.memberChairman.concat(this.memberRArray, this.structuredReChair, this.boardLiaison);
                        return [2 /*return*/];
                }
            });
        });
    };
    // array of tlap board member data for repeat for
    AboutUs.prototype.getSubCommitteeMembers = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, subMemberData, i, JSON_2, name, role;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.memberService.getSubCommitteeMembers()];
                    case 1:
                        _a.subMember = _b.sent();
                        subMemberData = this.subMember;
                        for (i = 0; i < subMemberData.length; i++) {
                            JSON_2 = null;
                            name = subMemberData[i].CommitteeMemberLabelName;
                            role = subMemberData[i].PositionCodeDescriptionDisplay;
                            JSON_2 = {
                                name: name,
                                role: role,
                            };
                            // loop through memberData name:role and put each Member by their array
                            if (JSON_2.role === "Chairman") {
                                this.subComChairman.push(JSON_2);
                            }
                            else if (JSON_2.role === "Member") {
                                this.memberArray.push(JSON_2);
                            }
                            else if (JSON_2.role === "Member/Resident") {
                                this.memberResident.push(JSON_2);
                            }
                            else if (JSON_2.role === "Vendor Liaison") {
                                this.vendorLiaison.push(JSON_2);
                            }
                            else if (JSON_2.role === "CDE Steering Committee Co-Chair") {
                                this.cdeSterringCoChair.push(JSON_2);
                            }
                            else if (JSON_2.role === "RadLex Steering Chairman") {
                                this.radLexSterringChair.push(JSON_2);
                            }
                            else if (JSON_2.role === "RadLex Steering Vice-Chairman") {
                                this.radLexSterringViceChair.push(JSON_2);
                            }
                            else if (JSON_2.role === "Board Liaison") {
                                this.subComBoardLiaison.push(JSON_2);
                            }
                            else {
                                this.staffContact.push(JSON_2);
                            }
                        }
                        // concat/sort member role arrays and push to front-end memberList array
                        this.subMembersList = this.subComChairman.concat(this.memberArray, this.memberResident, this.vendorLiaison, this.cdeSterringCoChair, this.radLexSterringChair, this.radLexSterringViceChair, this.subComBoardLiaison, this.staffContact);
                        return [2 /*return*/];
                }
            });
        });
    };
    AboutUs.prototype.activate = function (params) {
        if (params.page) {
            this.page = params.page;
        }
        else {
            this.page = "overview";
        }
    };
    AboutUs.prototype.attached = function () {
        this.collapseMenu();
        this.getCommitteeMembers();
        this.getSubCommitteeMembers();
    };
    AboutUs.prototype.changePage = function (page) {
        this.page = page;
        this.collapseMenu();
    };
    AboutUs.prototype.collapseMenu = function () {
        if (this.page == "tlap" ||
            // this.page == "members" ||
            this.page == "grading" ||
            this.page == "subcommittee") {
            $(".collapse").collapse("show");
        }
        else {
            $(".collapse").collapse("hide");
        }
    };
    AboutUs.prototype.bind = function () {
        this.base_url = config_1.Configuration.getBaseURI();
    };
    AboutUs = __decorate([
        aurelia_framework_1.autoinject,
        __metadata("design:paramtypes", [filter_state_1.FilterState, members_service_1.MemberService])
    ], AboutUs);
    return AboutUs;
}());
exports.AboutUs = AboutUs;
