"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteScrollPage = void 0;
// Scrolls to top of summary page after it renders
var RouteScrollPage = /** @class */ (function () {
    function RouteScrollPage() {
    }
    RouteScrollPage.prototype.run = function (instruction, next) {
        if (instruction.config.settings && instruction.config.settings.scrollToTop) {
            $('body').animate({ scrollTop: top }, 0);
        }
        return next();
    };
    return RouteScrollPage;
}());
exports.RouteScrollPage = RouteScrollPage;
