"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviewTemplate = void 0;
var aurelia_dialog_1 = require("aurelia-dialog");
var review_service_1 = require("./../../../services/review-service");
var template_service_1 = require("./../../../services/template-service");
var aurelia_router_1 = require("aurelia-router");
var aurelia_framework_1 = require("aurelia-framework");
var aurelia_store_1 = require("aurelia-store");
var operators_1 = require("rxjs/operators");
var template_1 = require("store/actions/template");
var ReviewTemplate = /** @class */ (function () {
    function ReviewTemplate(templateService, reviewService, router, dialogService) {
        this.templateService = templateService;
        this.reviewService = reviewService;
        this.router = router;
        this.dialogService = dialogService;
        this.showFormErrors = false;
        this.isPoppedOut = false;
    }
    ReviewTemplate.prototype.bind = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, templateDiv, templateContainer;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.clearReview();
                        this.complete = false;
                        this.reviewGrades = {};
                        if (!this.template.focusedtemplate) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.templateService.getTemplateDetails(this.template.focusedtemplate, this.template.focusedversion)];
                    case 1:
                        _a.templateData = _b.sent();
                        if (this.templateData["dataType"] == 'txt') {
                            this.templateData.templateData = this.templateData.templateData.replace(/\s/, '&nbsp;').replace(/\n/g, '<br>');
                        }
                        templateDiv = document.getElementById("template");
                        templateContainer = document.getElementById('review-template-container');
                        if (templateDiv) {
                            templateDiv.innerHTML = this.templateData.templateData;
                            templateContainer.style.height = String(templateContainer.offsetHeight) + "px";
                            templateContainer.style.maxHeight = "none";
                        }
                        this.getGrades();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ReviewTemplate.prototype.popoutTemplate = function () {
        var url = this.router.generate("template", { id: this.template.focusedtemplate, version: this.template.focusedversion });
        window.open(url, "templatepopout", "height=600,width=600");
        this.toggleShowTemplate();
    };
    ReviewTemplate.prototype.toggleShowTemplate = function () {
        this.isPoppedOut = !this.isPoppedOut;
    };
    ReviewTemplate.prototype.submitReview = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        //nomination answer is seperated because multiple booleans are bound to one radio button and i couldnt find a better way
                        //merge it into reviewGrades on submit
                        this.reviewGrades = Object.assign(this.reviewGrades, this.nominationAnswer);
                        if (!this.validateForm()) return [3 /*break*/, 2];
                        this.showFormErrors = false;
                        return [4 /*yield*/, this.reviewService.submitReview(this.template.focusedtemplate, this.reviewGrades, this.comments)];
                    case 1:
                        _a.sent();
                        this.router.navigate('#/profile/my-assignments');
                        return [3 /*break*/, 3];
                    case 2:
                        this.showFormErrors = true;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ReviewTemplate.prototype.getGrades = function () {
        return __awaiter(this, void 0, void 0, function () {
            var review, that;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.reviewService.getReviews(this.template.focusedtemplate, this.auth.userId)];
                    case 1:
                        review = _a.sent();
                        review = review[0];
                        that = this;
                        if (review && review.complete == 1) {
                            //manually set each grade to not break binding
                            Object.keys(review).forEach(function (key, index) {
                                //if anser was null, keep it null for 'N/A' option
                                if (review[key] !== null) {
                                    //review grades come back as strings '1' or '0' so to convert to boolean, first convert to number
                                    that.reviewGrades[key] = Boolean(Number(review[key]));
                                }
                                else {
                                    that.reviewGrades[key] = null;
                                }
                            });
                            this.comments.additionalComment = review.comment;
                            this.comments.notEndorsedComment = review.reason;
                            this.complete = true;
                        }
                        else {
                            this.complete = false;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ReviewTemplate.prototype.back = function () {
        (0, aurelia_store_1.dispatchify)(template_1.defocusTemplate)();
        this.router.navigate('#/profile/my-assignments');
    };
    ReviewTemplate.prototype.clearReview = function () {
        this.reviewGrades = {};
        this.nominationAnswer = {};
        this.comments = {
            additionalComment: '',
            notEndorsedComment: ''
        };
    };
    //form is valid when radio questions are done
    ReviewTemplate.prototype.validateForm = function () {
        var answers = Object.values(this.reviewGrades);
        //return false if answers include undefined
        return !answers.includes(undefined);
    };
    //one question sets 3 vars so heres hacky way to set them
    //change.delegate will call this function and set each boolean individually
    ReviewTemplate.prototype.setNominateAnswer = function (setEndorsed, setPublic, setDeprecated) {
        // this.reviewGrades.endorse = setEndorsed;
        // this.reviewGrades.public = setPublic;
        // this.reviewGrades.deprecate = setDeprecated;
    };
    ReviewTemplate = __decorate([
        aurelia_framework_1.autoinject,
        (0, aurelia_store_1.connectTo)({
            selector: {
                template: function (store) { return store.state.pipe((0, operators_1.pluck)('template')); },
                auth: function (store) { return store.state.pipe((0, operators_1.pluck)('auth')); }
            }
        }),
        __metadata("design:paramtypes", [template_service_1.TemplateService, review_service_1.ReviewService, aurelia_router_1.Router, aurelia_dialog_1.DialogService])
    ], ReviewTemplate);
    return ReviewTemplate;
}());
exports.ReviewTemplate = ReviewTemplate;
