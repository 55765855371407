"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// node_modules
var sweetalert2_1 = require("sweetalert2");
var ConfirmationPopup = /** @class */ (function () {
    function ConfirmationPopup(message) {
        this.render(message);
    }
    ConfirmationPopup.prototype.render = function (message) {
        (0, sweetalert2_1.default)({
            html: "<strong>".concat(message, "</strong>"),
            type: 'success',
            timer: 1500,
            showConfirmButton: false,
        });
        return false;
    };
    return ConfirmationPopup;
}());
exports.default = ConfirmationPopup;
