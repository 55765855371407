"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpecFilters = void 0;
var spec_service_1 = require("./../../../services/spec-service");
var aurelia_event_aggregator_1 = require("aurelia-event-aggregator");
var aurelia_framework_1 = require("aurelia-framework");
var aurelia_store_1 = require("aurelia-store");
var operators_1 = require("rxjs/operators");
var SpecFilters = /** @class */ (function () {
    function SpecFilters(EventAggregator, specservice, bindingengine) {
        this.EventAggregator = EventAggregator;
        this.specservice = specservice;
        this.bindingengine = bindingengine;
        this.specialties = [];
        this.ea = EventAggregator;
    }
    SpecFilters.prototype.bind = function () {
        var _this = this;
        this.getSpecialties();
        this.bindingengine.propertyObserver(this.auth, "loggedIn").subscribe(function (newValue, oldValue) {
            _this.getSpecialties();
        });
    };
    SpecFilters.prototype.publishFilter = function () {
        this.clearFilters();
        this.filterobj['search'] = '';
        this.ea.publish("filters");
    };
    SpecFilters.prototype.getSidebarSpec = function (spec) {
        //set no filter if clicking active filter
        this.filterobj['sidebar'] == spec['code'] ? this.filterobj['sidebar'] = "" : this.filterobj['sidebar'] = spec["code"];
        this.publishFilter();
    };
    SpecFilters.prototype.clearFilters = function () {
        for (var filter in this.filterobj) {
            if (typeof this.filterobj[filter] != 'string') {
                for (var i in this.filterobj[filter]) {
                    this.filterobj[filter][i].checked = false;
                    this.filterobj[filter][i].selected = false;
                }
            }
        }
    };
    SpecFilters.prototype.getSpecialties = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.specservice.getSpecs()];
                    case 1:
                        _a.specialties = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        aurelia_framework_1.bindable,
        __metadata("design:type", Object)
    ], SpecFilters.prototype, "filterobj", void 0);
    SpecFilters = __decorate([
        (aurelia_framework_1.autoinject),
        (0, aurelia_store_1.connectTo)({
            selector: {
                auth: function (store) { return store.state.pipe((0, operators_1.pluck)('auth')); },
                template: function (store) { return store.state.pipe((0, operators_1.pluck)('template')); }
            }
        }),
        __metadata("design:paramtypes", [aurelia_event_aggregator_1.EventAggregator, spec_service_1.SpecService, aurelia_framework_1.BindingEngine])
    ], SpecFilters);
    return SpecFilters;
}());
exports.SpecFilters = SpecFilters;
