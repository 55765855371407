"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadTemplate = void 0;
var spec_service_1 = require("services/spec-service");
var organization_service_1 = require("./../../../services/organization-service");
var template_service_1 = require("./../../../services/template-service");
var aurelia_router_1 = require("aurelia-router");
var upload_service_1 = require("./../../../services/upload-service");
var aurelia_framework_1 = require("aurelia-framework");
var aurelia_event_aggregator_1 = require("aurelia-event-aggregator");
var aurelia_store_1 = require("aurelia-store");
var operators_1 = require("rxjs/operators");
var template_1 = require("store/actions/template");
var user_service_1 = require("services/user-service");
var language_service_1 = require("services/language-service");
var radlex_service_1 = require("services/radlex-service");
var loinc_service_1 = require("services/loinc-service");
var delete_template_1 = require("../../modals/delete-template/delete-template");
var aurelia_dialog_1 = require("aurelia-dialog");
var sweetalert2_1 = require("sweetalert2");
var UploadTemplate = /** @class */ (function () {
    function UploadTemplate(eventAggregator, UploadService, Router, TemplateService, specservice, languageservice, binding, userservice, orgservice, radlexservice, loincservice, dialogService) {
        this.specservice = specservice;
        this.languageservice = languageservice;
        this.binding = binding;
        this.userservice = userservice;
        this.orgservice = orgservice;
        this.radlexservice = radlexservice;
        this.loincservice = loincservice;
        this.dialogService = dialogService;
        this.warningText = false;
        this.formError = {
            title: false,
            specialty: false,
            language: false,
            template: false,
            review: false
        };
        this.header = 'Upload Template';
        this.uploadType = '';
        this.txtData = '';
        this.loincData = [];
        this.updateTemplateMetadataOnly = false;
        // only show errors after unsuccessful submit
        this.attemptedSubmit = false;
        this.templateWasSubmitted = false;
        this.deleteEnabled = false;
        this.loading = false;
        this.uploadservice = UploadService;
        this.ea = eventAggregator;
        this.router = Router;
        this.templateservice = TemplateService;
        this.dialogService = dialogService;
    }
    UploadTemplate.prototype.bind = function () {
        // set variables to default to avoid previous template from staying on page
        this.formData = this.resetForm();
    };
    UploadTemplate.prototype.resetForm = function () {
        this.uploadType = '';
        this.templateData = null;
        this.htmlData = null;
        this.updateTemplateMetadataOnly = false;
        return {
            title: '',
            description: '',
            specialty: [],
            institution: [],
            radlex: [],
            procedures: [],
            language: '',
            template: null,
            review: null,
            references: [],
            coauthors: [],
            translators: [],
            createdBy: '',
            template_current_version: null
        };
    };
    UploadTemplate.prototype.attached = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        $('[data-toggle="tooltip"]').tooltip();
                        document.getElementById('htmlFile').value = null;
                        // setup observers for any change in formData for validation
                        this.initObservers();
                        this.saveTemplateSubscription = this.ea.subscribe('saveTemplate', this.saveTemplateSubscriptionFunc.bind(this));
                        _a = this;
                        return [4 /*yield*/, this.specservice.getSpecs()];
                    case 1:
                        _a.specialties = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.languageservice.getLanguages()];
                    case 2:
                        _b.languages = _c.sent();
                        return [4 /*yield*/, this.loadLoinc()];
                    case 3:
                        _c.sent();
                        if (!this.template.focusededit) return [3 /*break*/, 5];
                        // set page header
                        this.header = 'Edit Template';
                        return [4 /*yield*/, this.getTemplateDetails()];
                    case 4:
                        _c.sent();
                        if (this.templateData.template_status === 'deleted') {
                            this.router.navigate("#/profile/my-templates");
                        }
                        if (this.templateData.is_public === "1" || this.templateData.TLAP_Approved === "1" || !this.auth.editor) {
                            this.deleteEnabled = false;
                        }
                        else {
                            // let the editor see the delete button when not public or approved
                            this.deleteEnabled = true;
                        }
                        _c.label = 5;
                    case 5:
                        this.setFormDefault();
                        // add 'created by' field of the original Author, at the moment for Editor visibility only
                        this.addCreatedByAuthor();
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadTemplate.prototype.loadLoinc = function () {
        return __awaiter(this, void 0, void 0, function () {
            var loinc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loincservice.loadLoinc()];
                    case 1:
                        loinc = _a.sent();
                        if (loinc instanceof Array) {
                            this.loincData = loinc;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadTemplate.prototype.initObservers = function () {
        var _this = this;
        // subscribe to every property of data for validation
        Object.getOwnPropertyNames(this.formData).forEach(function (p) {
            // need to check if property value is array since its a different observer function
            if (Array.isArray(_this.formData[p])) {
                _this.subscriptions = _this.binding.collectionObserver(_this.formData[p])
                    .subscribe(function (splices) { return _this.validateForm(); });
            }
            else {
                _this.subscriptions = _this.binding.propertyObserver(_this.formData, p)
                    .subscribe(function (newValue, oldValue) { return _this.validateForm(); });
            }
        });
    };
    UploadTemplate.prototype.detached = function () {
        this.subscriptions.dispose();
        this.saveTemplateSubscription.dispose();
    };
    UploadTemplate.prototype.toSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.page = 'submit';
                        // un-focus edit on template
                        return [4 /*yield*/, (0, aurelia_store_1.dispatchify)(template_1.focusTemplate)(this.template.focusedtemplate, this.template.focusedversion)];
                    case 1:
                        // un-focus edit on template
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadTemplate.prototype.getTemplateDetails = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.templateservice.getTemplateDetails(this.template.focusedtemplate, this.template.focusedversion)];
                    case 1:
                        _a.templateData = (_b.sent());
                        if (this.template.focusedversion !== null) {
                            this.deleteEnabled = false;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadTemplate.prototype.deleteTemplate = function (template) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.dialogService.open({ viewModel: delete_template_1.DeleteTemplate, model: template, lock: false }).whenClosed(function (response) {
                    if (!response.wasCancelled) {
                        _this.router.navigate('#/profile/my-templates');
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    UploadTemplate.prototype.saveTemplateSubscriptionFunc = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.submitTemplate()];
                    case 1:
                        _a.sent();
                        this.ea.publish("subscriptionSaveEnd");
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadTemplate.prototype.submitTemplate = function () {
        return __awaiter(this, void 0, void 0, function () {
            var templateType, isValid, updateTemplate, editTemplate;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // disable submit button
                        this.templateWasSubmitted = true;
                        templateType = this.selectTemplateType();
                        isValid = this.validateForm();
                        if (!isValid) return [3 /*break*/, 10];
                        this.ea.publish('loading', { progress: true, distance: 0, text: 'Saving template data... Do not navigate away from this page!!' });
                        if (!this.updateTemplateMetadataOnly) return [3 /*break*/, 5];
                        // update template medtadata without creating a new version
                        this.ea.publish('loading', { progress: true, distance: 50, text: 'Saving template data... Do not navigate away from this page!!' });
                        return [4 /*yield*/, this.uploadservice.updateTemplate(this.formData, this.template.focusedtemplate)];
                    case 1:
                        updateTemplate = _a.sent();
                        if (!updateTemplate.id) return [3 /*break*/, 3];
                        return [4 /*yield*/, (0, aurelia_store_1.dispatchify)(template_1.focusTemplate)(updateTemplate.id, updateTemplate.created)];
                    case 2:
                        _a.sent();
                        this.router.navigateToRoute('profile', { tab: 'my-templates', id: updateTemplate.id, version: updateTemplate.created }, { replace: true, trigger: true });
                        return [3 /*break*/, 4];
                    case 3:
                        if (!updateTemplate.err) {
                            this.router.navigate("#/profile/my-templates");
                        }
                        _a.label = 4;
                    case 4: return [3 /*break*/, 9];
                    case 5:
                        // edit or upload new template,
                        // if edit template is null, will upload new template
                        this.ea.publish('loading', { progress: true, distance: 50, text: 'Saving template data... Do not navigate away from this page!!' });
                        return [4 /*yield*/, this.uploadservice.uploadTemplate(this.formData, templateType, this.template.focusedtemplate)];
                    case 6:
                        editTemplate = _a.sent();
                        if (!editTemplate.id) return [3 /*break*/, 8];
                        return [4 /*yield*/, (0, aurelia_store_1.dispatchify)(template_1.focusTemplate)(editTemplate.id, editTemplate.created)];
                    case 7:
                        _a.sent();
                        this.router.navigateToRoute('profile', { tab: 'my-templates', id: editTemplate.id, version: editTemplate.created }, { replace: true, trigger: true });
                        return [3 /*break*/, 9];
                    case 8:
                        if (!editTemplate.err) {
                            this.router.navigate("#/profile/my-templates");
                        }
                        _a.label = 9;
                    case 9: return [3 /*break*/, 11];
                    case 10:
                        this.warningText = false;
                        // show form errors
                        this.attemptedSubmit = true;
                        // re-enable submit button
                        this.templateWasSubmitted = false;
                        _a.label = 11;
                    case 11:
                        this.ea.publish('loaded');
                        return [2 /*return*/];
                }
            });
        });
    };
    // check for errors
    UploadTemplate.prototype.validateForm = function () {
        // loop through the required fields set in formError and validate them
        // tslint:disable-next-line: forin
        for (var formInput in this.formError) {
            // different validation for different data types
            switch (typeof (this.formData[formInput])) {
                case 'string':
                    if (this.formData[formInput] == null || this.formData[formInput].trim() == '') {
                        this.formError[formInput] = true;
                    }
                    else {
                        this.formError[formInput] = false;
                    }
                    break;
                case 'object':
                    if (this.formData[formInput] == null || (Array.isArray(this.formData[formInput]) && !this.formData[formInput].length)) {
                        this.formError[formInput] = true;
                    }
                    else {
                        this.formError[formInput] = false;
                    }
                    break;
                default:
                    break;
            }
        }
        if (this.template.focusededit && this.uploadType === '') {
            this.updateTemplateMetadataOnly = true;
            this.formError.template = false;
        }
        else {
            this.updateTemplateMetadataOnly = false;
        }
        return !Object.values(this.formError).includes(true);
    };
    // validate that file is html/zip
    UploadTemplate.prototype.validateFile = function () {
        if (this.htmlData && this.htmlData.length) {
            if (this.htmlData[0].type == 'text/html' || ['application/zip', 'application/octet-stream', 'application/x-zip-compressed', ' multipart/x-zip'].includes(this.htmlData[0].type)) {
                this.warningText = false;
            }
            else {
                var htmlInput = document.getElementById('htmlFile');
                htmlInput.value = null;
                this.htmlData = null;
                this.warningText = true;
            }
        }
    };
    UploadTemplate.prototype.selectTemplateType = function () {
        // use either the file upload or text box for template data
        // set template type for api url
        var templateType;
        if (this.uploadType == 'html' && this.htmlData) {
            this.formData.template = this.htmlData[0];
            templateType = this.htmlData[0].type == 'text/html' ? 'html' : 'zip';
        }
        else {
            this.formData.template = this.txtData.trim();
            templateType = 'txt';
        }
        return templateType;
    };
    // fill out form with existing template data
    UploadTemplate.prototype.setFormDefault = function () {
        if (this.templateData) {
            // keep previous author
            this.formData.author = this.templateData.author;
            // set title
            this.formData.title = this.templateData.title;
            // set description
            this.formData.description = this.templateData.description;
            // check all specialty boxes
            // form only uses code
            // Specialties
            var specialty = this.templateData.specialty.map(function (element) {
                return element.code;
            });
            // get rid of duplicate by converting array to set and back
            this.formData.specialty = Array.from(new Set(specialty));
            // Institution(s)
            var institutions = [];
            var uniqueOrgArray = Array.from(new Set(this.templateData.organizations.map(function (org) { return org.name; })));
            var _loop_1 = function (uniqueOrg) {
                institutions.push(this_1.templateData.organizations.find(function (org) { return org.name === uniqueOrg; }));
            };
            var this_1 = this;
            for (var _i = 0, uniqueOrgArray_1 = uniqueOrgArray; _i < uniqueOrgArray_1.length; _i++) {
                var uniqueOrg = uniqueOrgArray_1[_i];
                _loop_1(uniqueOrg);
            }
            this.formData.institution = institutions;
            // Translator(s)
            if (this.templateData.translators !== null) {
                var translators = [];
                var uniqueTranslatorsArray = Array.from(new Set(this.templateData.translators.map(function (translator) { return translator.fullname; })));
                var _loop_2 = function (uniqueName) {
                    translators.push(this_2.templateData.translators.find(function (translator) { return translator.fullname === uniqueName; }));
                };
                var this_2 = this;
                for (var _a = 0, uniqueTranslatorsArray_1 = uniqueTranslatorsArray; _a < uniqueTranslatorsArray_1.length; _a++) {
                    var uniqueName = uniqueTranslatorsArray_1[_a];
                    _loop_2(uniqueName);
                }
                this.formData.translators = translators;
            }
            // Co-author(s)
            var coauthors = [];
            var uniqueNamesArray = Array.from(new Set(this.templateData.coauthors.map(function (coauthor) { return coauthor.fullname; })));
            var _loop_3 = function (uniqueName) {
                coauthors.push(this_3.templateData.coauthors.find(function (coauthor) { return coauthor.fullname === uniqueName; }));
            };
            var this_3 = this;
            for (var _b = 0, uniqueNamesArray_1 = uniqueNamesArray; _b < uniqueNamesArray_1.length; _b++) {
                var uniqueName = uniqueNamesArray_1[_b];
                _loop_3(uniqueName);
            }
            this.formData.coauthors = coauthors;
            // Radlex Terms
            var radlexs = [];
            var uniqueRadlexArray = Array.from(new Set(this.templateData.radlex.map(function (rad) { return rad.id; })));
            var _loop_4 = function (uniqueRadlex) {
                radlexs.push(this_4.templateData.radlex.find(function (rad) { return rad.id === uniqueRadlex; }));
            };
            var this_4 = this;
            for (var _c = 0, uniqueRadlexArray_1 = uniqueRadlexArray; _c < uniqueRadlexArray_1.length; _c++) {
                var uniqueRadlex = uniqueRadlexArray_1[_c];
                _loop_4(uniqueRadlex);
            }
            this.formData.radlex = radlexs;
            // Procedures
            var procedures = [];
            var uniqueProcedureArray = Array.from(new Set(this.templateData.procedures.map(function (procedure) { return procedure.procedure_id; })));
            var _loop_5 = function (uniqueProcedure) {
                procedures.push(this_5.templateData.procedures.find(function (procedure) { return procedure.procedure_id === uniqueProcedure; }));
            };
            var this_5 = this;
            for (var _d = 0, uniqueProcedureArray_1 = uniqueProcedureArray; _d < uniqueProcedureArray_1.length; _d++) {
                var uniqueProcedure = uniqueProcedureArray_1[_d];
                _loop_5(uniqueProcedure);
            }
            this.formData.procedures = procedures.map(function (procedure) { return ({
                display: procedure.procedure_name,
                code: procedure.procedure_id,
            }); });
            // Reference(s)
            var references = this.templateData.references.map(function (ref) {
                return ref;
            });
            // get rid of duplicate by converting array to set and back
            this.formData.references = Array.from(new Set(references));
            // set language
            this.formData.language = this.templateData.langCode;
            // set template data
            this.formData.template = this.templateData.templateData;
            // set template version if editing
            if (this.templateData.currentVersion) {
                this.formData.template_current_version = this.templateData.currentVersion;
            }
        }
        else {
            this.formData.author = this.auth.userId;
            // set header
            this.header = 'Upload Template';
        }
    };
    UploadTemplate.prototype.genericTxt = function () {
        this.txtData = 'Procedure: [ ]\nClinical information: [ ]\nTechnique: [ ]\nComparison: [ ]\nObservations: [ ]\nSummary/Impression: [ ]';
    };
    UploadTemplate.prototype.searchLoinc = function (search) {
        return this.loincData.filter(function (loinc) { return loinc.display.match(new RegExp(search, 'i')); });
    };
    UploadTemplate.prototype.addLoinc = function (term) {
        if (!this.formData.procedures.some(function (proc) { return proc.code === term.code; })) {
            this.formData.procedures.push(term);
        }
    };
    UploadTemplate.prototype.searchRadlex = function (search) {
        return __awaiter(this, void 0, void 0, function () {
            var rad;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.radlexservice.searchRadlex(search)];
                    case 1:
                        rad = _a.sent();
                        return [2 /*return*/, rad];
                }
            });
        });
    };
    UploadTemplate.prototype.addRadlex = function (term) {
        // if term isn't already in list
        if (!this.formData.radlex.some(function (rad) { return rad.id == term.id; })) {
            this.formData.radlex.push(term);
        }
    };
    UploadTemplate.prototype.addReference = function () {
        var _this = this;
        // data check for duplicate references
        if (this.formData.references.find(function (ref) { return ref.toLowerCase() === _this.referenceText.toLowerCase(); })) {
            (0, sweetalert2_1.default)('You cannot add the same reference twice');
            return false;
        }
        if (this.referenceText.trim()) {
            this.formData.references.push(this.referenceText);
            this.referenceText = '';
        }
    };
    UploadTemplate.prototype.searchUsers = function (search) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.userservice.getUsers(search)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    UploadTemplate.prototype.updateAuthor = function (author) {
        if (!this.templateData) {
            this.templateData = {
                firstname: author.firstname,
                lastname: author.lastname,
            };
        }
        else {
            this.templateData.firstname = author.firstname;
            this.templateData.lastname = author.lastname;
        }
        this.formData.author = author.id;
    };
    UploadTemplate.prototype.addCoauthor = function (coauthor) {
        var fullname = "".concat(coauthor.firstname, " ").concat(coauthor.lastname);
        var data = { id: coauthor.id, fullname: fullname };
        if (coauthor.id == null || !this.formData.coauthors.some(function (coauth) { return coauth.id === coauthor.id; })) {
            this.formData.coauthors.push(data);
        }
    };
    UploadTemplate.prototype.manualAddCoauthor = function (name) {
        // data check for duplicate co-authors
        if (this.formData.coauthors.find(function (author) { return author.fullname.toLowerCase() === name.toLowerCase(); })) {
            (0, sweetalert2_1.default)('You cannot add the same co-author twice');
            return false;
        }
        if (name.trim()) {
            var data = { id: null, fullname: name };
            this.formData.coauthors.push(data);
        }
    };
    UploadTemplate.prototype.addCreatedByAuthor = function () {
        // during editing a template
        if (this.templateData) {
            if (this.auth.editor && this.templateData.originalAuthor !== null && this.templateData.originalAuthorFirstname !== null) {
                this.formData.createdBy = "".concat(this.templateData.originalAuthorFirstname, " ").concat(this.templateData.originalAuthorLastName);
            }
        }
        else {
            // when first creating a template
            if (this.auth.editor) {
                this.formData.createdBy = this.auth.name;
            }
        }
    };
    UploadTemplate.prototype.addTranslator = function (translator) {
        var fullname = "".concat(translator.firstname, " ").concat(translator.lastname);
        var data = { id: translator.id, fullname: fullname };
        if (translator.id == null || !this.formData.translators.some(function (translators) { return translators.id === translator.id; })) {
            this.formData.translators.push(data);
        }
    };
    UploadTemplate.prototype.manualAddTranslator = function (name) {
        // data check for duplicate translators
        if (this.formData.translators.find(function (translators) { return translators.fullname.toLowerCase() === name.toLowerCase(); })) {
            (0, sweetalert2_1.default)('You cannot add the same translator twice');
            return false;
        }
        if (name.trim()) {
            var data = { id: null, fullname: name };
            this.formData.translators.push(data);
        }
    };
    UploadTemplate.prototype.searchInstitutions = function (search) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.orgservice.getOrganizations(search)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    UploadTemplate.prototype.addInstitution = function (institution) {
        var data = { id: institution.id, code: institution.code, name: institution.name };
        if (institution.code == null || !this.formData.institution.some(function (inst) { return inst.code == institution.code; })) {
            this.formData.institution.push(data);
        }
    };
    UploadTemplate.prototype.manualAddInstitution = function (name) {
        // data check for duplicate co-authors
        if (this.formData.institution.find(function (institution) { return institution.name.toLowerCase() === name.toLowerCase(); })) {
            (0, sweetalert2_1.default)('You cannot add the same institution twice');
            return false;
        }
        if (name.trim()) {
            var data = { id: null, code: null, name: name };
            this.formData.institution.push(data);
        }
    };
    UploadTemplate.prototype.removeFromArray = function (target, array) {
        for (var index in array) {
            if (target == array[index]) {
                array.splice(Number(index), 1);
            }
        }
    };
    __decorate([
        aurelia_framework_1.bindable,
        __metadata("design:type", Object)
    ], UploadTemplate.prototype, "page", void 0);
    UploadTemplate = __decorate([
        aurelia_framework_1.autoinject,
        (0, aurelia_store_1.connectTo)({
            selector: {
                template: function (store) { return store.state.pipe((0, operators_1.pluck)('template')); },
                auth: function (store) { return store.state.pipe((0, operators_1.pluck)('auth')); }
            }
        }),
        __metadata("design:paramtypes", [aurelia_event_aggregator_1.EventAggregator, upload_service_1.UploadService, aurelia_router_1.Router, template_service_1.TemplateService, spec_service_1.SpecService, language_service_1.LanguageService,
            aurelia_framework_1.BindingEngine, user_service_1.UserService, organization_service_1.OrganizationService, radlex_service_1.RadlexService, loinc_service_1.LoincService, aurelia_dialog_1.DialogService])
    ], UploadTemplate);
    return UploadTemplate;
}());
exports.UploadTemplate = UploadTemplate;
