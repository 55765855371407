"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Utils = void 0;
var _ = require("underscore");
var Utils = /** @class */ (function () {
    function Utils() {
    }
    // returns list of list groups by n
    Utils.partition = function (items, n) {
        var result = _.groupBy(items, function (item, i) {
            return Math.floor(i % n);
        });
        return _.values(result);
    };
    Utils.prototype.isIOS = function () {
        return (navigator.userAgent.match('FxiOS') || navigator.userAgent.match('CriOS') || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i));
    };
    Utils.prototype.iOSDownloadPreview = function (path, data) {
        var form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', path);
        form.setAttribute('id', 'preview-form');
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                var hiddenField = document.createElement('input');
                hiddenField.setAttribute('type', 'hidden');
                hiddenField.setAttribute('name', key);
                hiddenField.setAttribute('value', data[key]);
                form.appendChild(hiddenField);
            }
        }
        document.body.appendChild(form);
        form.submit();
    };
    Utils.prototype.deleteCookie = function (name) {
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;';
    };
    return Utils;
}());
exports.Utils = Utils;
