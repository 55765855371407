"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateDetails = void 0;
var filter_state_1 = require("./../../state/filter-state");
var download_service_1 = require("./../../services/download-service");
var template_service_1 = require("./../../services/template-service");
var aurelia_framework_1 = require("aurelia-framework");
var aurelia_framework_2 = require("aurelia-framework");
var aurelia_router_1 = require("aurelia-router");
var aurelia_dialog_1 = require("aurelia-dialog");
var aurelia_store_1 = require("aurelia-store");
var operators_1 = require("rxjs/operators");
var template_1 = require("store/actions/template");
var template_2 = require("store/actions/template");
var delete_template_1 = require("../modals/delete-template/delete-template");
var TemplateDetails = /** @class */ (function () {
    function TemplateDetails(TemplateService, DownloadService, FilterState, dialogService, Router) {
        this.dialogService = dialogService;
        this.currentVersion = true;
        this.tooltipText = 'Favorite';
        this.deleteEnabled = false;
        this.templateservice = TemplateService;
        this.downloadservice = DownloadService;
        this.filterstate = FilterState;
        this.dialogService = dialogService;
        this.router = Router;
    }
    TemplateDetails.prototype.bind = function () {
        // set variables to null to avoid previous template from staying on page
        this.templateData = null;
        this.tooltipText = this.auth.loggedIn ? 'Favorite this Template' : 'Please login to Favorite';
    };
    TemplateDetails.prototype.attached = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.template.focusedtemplate) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getTemplateDetails()];
                    case 1:
                        _a.sent();
                        if (this.templateData.template_status === 'deleted') {
                            this.router.navigate("#/profile/my-templates");
                        }
                        _a.label = 2;
                    case 2:
                        if (this.templateData.is_public === 1 || this.templateData.TLAP_Approved === 1 || !this.auth.editor || this.templateData.published_at !== null) {
                            this.deleteEnabled = false;
                        }
                        else {
                            // let the editor see the delete button when not public or approved
                            this.deleteEnabled = true;
                        }
                        $('[data-toggle="tooltip"]').tooltip();
                        return [2 /*return*/];
                }
            });
        });
    };
    // get data for display
    TemplateDetails.prototype.getTemplateDetails = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.templateservice.getTemplateDetails(this.template.focusedtemplate, this.template.focusedversion)];
                    case 1:
                        _a.templateData = _b.sent();
                        this.removeDuplicatesFromView();
                        this.formatData();
                        this.addCreatedByAuthor();
                        return [2 /*return*/];
                }
            });
        });
    };
    TemplateDetails.prototype.removeDuplicatesFromView = function () {
        // Band-Aid solution to not show duplication data when viewing template from Table, My Templates, Favorites, etc.
        // Specialties
        var specialties = [];
        var uniqueSpecArray = Array.from(new Set(this.templateData.specialty.map(function (spec) { return spec.code; })));
        var _loop_1 = function (uniqueSpec) {
            specialties.push(this_1.templateData.specialty.find(function (spec) { return spec.code === uniqueSpec; }));
        };
        var this_1 = this;
        for (var _i = 0, uniqueSpecArray_1 = uniqueSpecArray; _i < uniqueSpecArray_1.length; _i++) {
            var uniqueSpec = uniqueSpecArray_1[_i];
            _loop_1(uniqueSpec);
        }
        this.templateData.specialty = specialties;
        // Institution(s)
        var institutions = [];
        var uniqueOrgArray = Array.from(new Set(this.templateData.organizations.map(function (org) { return org.name; })));
        var _loop_2 = function (uniqueOrg) {
            institutions.push(this_2.templateData.organizations.find(function (org) { return org.name === uniqueOrg; }));
        };
        var this_2 = this;
        for (var _a = 0, uniqueOrgArray_1 = uniqueOrgArray; _a < uniqueOrgArray_1.length; _a++) {
            var uniqueOrg = uniqueOrgArray_1[_a];
            _loop_2(uniqueOrg);
        }
        this.templateData.organizations = institutions;
        if (this.templateData.translators !== null) {
            // Translated by
            var translators = [];
            var uniqueTranslatorsArray = Array.from(new Set(this.templateData.translators.map(function (translators) { return translators.fullname; })));
            var _loop_3 = function (uniqueName) {
                translators.push(this_3.templateData.translators.find(function (translator) { return translator.fullname === uniqueName; }));
            };
            var this_3 = this;
            for (var _b = 0, uniqueTranslatorsArray_1 = uniqueTranslatorsArray; _b < uniqueTranslatorsArray_1.length; _b++) {
                var uniqueName = uniqueTranslatorsArray_1[_b];
                _loop_3(uniqueName);
            }
            this.templateData.translators = translators;
        }
        // Co-author(s)
        var coauthors = [];
        var uniqueNamesArray = Array.from(new Set(this.templateData.coauthors.map(function (coauthor) { return coauthor.fullname; })));
        var _loop_4 = function (uniqueName) {
            coauthors.push(this_4.templateData.coauthors.find(function (coauthor) { return coauthor.fullname === uniqueName; }));
        };
        var this_4 = this;
        for (var _c = 0, uniqueNamesArray_1 = uniqueNamesArray; _c < uniqueNamesArray_1.length; _c++) {
            var uniqueName = uniqueNamesArray_1[_c];
            _loop_4(uniqueName);
        }
        this.templateData.coauthors = coauthors;
        // Radlex Terms
        var radlexs = [];
        var uniqueRadlexArray = Array.from(new Set(this.templateData.radlex.map(function (rad) { return rad.id; })));
        var _loop_5 = function (uniqueRadlex) {
            radlexs.push(this_5.templateData.radlex.find(function (rad) { return rad.id === uniqueRadlex; }));
        };
        var this_5 = this;
        for (var _d = 0, uniqueRadlexArray_1 = uniqueRadlexArray; _d < uniqueRadlexArray_1.length; _d++) {
            var uniqueRadlex = uniqueRadlexArray_1[_d];
            _loop_5(uniqueRadlex);
        }
        this.templateData.radlex = radlexs;
        // Reference(s)
        var references = this.templateData.references.map(function (ref) {
            return ref;
        });
        this.templateData.references = Array.from(new Set(references));
    };
    // convert strings to arrays and display htmlData on page
    TemplateDetails.prototype.formatData = function () {
        this.templateData['isFavorite'] = this.templateData['isFavorite'] == 1 ? true : false;
        // need to add white spaces if template is txt
        if (this.templateData['dataType'] == 'txt') {
            this.templateData['templateData'] = this.templateData['templateData'].replace(/\s/, '&nbsp;').replace(/\n/g, '<br>');
        }
        this.setTemplateDivHeight();
    };
    TemplateDetails.prototype.changeVersion = function (version) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (version) {
                            this.currentVersion = false;
                        }
                        else {
                            this.currentVersion = true;
                        }
                        return [4 /*yield*/, (0, aurelia_store_1.dispatchify)(template_2.changeVersion)(version)];
                    case 1:
                        _a.sent();
                        this.getTemplateDetails();
                        return [2 /*return*/];
                }
            });
        });
    };
    TemplateDetails.prototype.setTemplateDivHeight = function () {
        var templateDiv = document.getElementById('template');
        var templateContainer = document.getElementById('templateContainer');
        if (templateDiv) {
            // reset style heights so height doesnt carry over from template to template
            templateContainer.style.height = 'auto';
            templateContainer.style.maxHeight = '500px';
            // set height to the current height, height is maxed out at 500px in css
            // this is so removing max height on the next line wont expand the div to fit the content
            templateContainer.style.height = String(templateContainer.offsetHeight) + 'px';
            // get rid of max height so div can be expanded further
            templateContainer.style.maxHeight = 'none';
        }
    };
    TemplateDetails.prototype.editTemplate = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, (0, aurelia_store_1.dispatchify)(template_1.focusTemplate)(this.template.focusedtemplate, this.template.focusedversion, true)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TemplateDetails.prototype.favTemplate = function () {
        if (!this.auth.loggedIn) {
            return false;
        }
        else {
            if (!this.templateData.isFavorite) {
                this.templateData.isFavorite = true;
                this.templateData.favCount++;
            }
            else {
                this.templateData.isFavorite = false;
                this.templateData.favCount--;
            }
            this.templateservice.favoriteTemplate(this.template.focusedtemplate, this.templateData.isFavorite, this.auth.userId);
        }
    };
    TemplateDetails.prototype.downloadHTML = function () {
        var filename = this.templateData.title + '.html';
        this.downloadservice.downloadTemplates([this.templateData.selectedVersion], 'html', filename);
    };
    // For Published/table view of a template
    TemplateDetails.prototype.addCreatedByAuthor = function () {
        if (this.templateData) {
            if (this.auth.editor && this.templateData.originalAuthor !== null && this.templateData.originalAuthorFirstname !== null) {
                this.templateData.createdBy = "".concat(this.templateData.originalAuthorFirstname, " ").concat(this.templateData.originalAuthorLastName);
            }
            if (this.auth.editor && this.templateData.originalAuthorFirstname === null) {
                this.templateData.createdBy = this.templateData.author;
            }
        }
    };
    TemplateDetails.prototype.deleteTemplate = function (template) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.dialogService.open({ viewModel: delete_template_1.DeleteTemplate, model: template, lock: false }).whenClosed(function (response) {
                    if (!response.wasCancelled) {
                        _this.router.navigate('#/profile/my-templates');
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    __decorate([
        aurelia_framework_1.bindable,
        __metadata("design:type", Object)
    ], TemplateDetails.prototype, "back", void 0);
    TemplateDetails = __decorate([
        aurelia_framework_2.autoinject,
        (0, aurelia_store_1.connectTo)({
            selector: {
                auth: function (store) { return store.state.pipe((0, operators_1.pluck)('auth')); },
                template: function (store) { return store.state.pipe((0, operators_1.pluck)('template')); }
            }
        }),
        __metadata("design:paramtypes", [template_service_1.TemplateService, download_service_1.DownloadService, filter_state_1.FilterState, aurelia_dialog_1.DialogService, aurelia_router_1.Router])
    ], TemplateDetails);
    return TemplateDetails;
}());
exports.TemplateDetails = TemplateDetails;
