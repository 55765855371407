"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublishTemplate = void 0;
var aurelia_framework_1 = require("aurelia-framework");
var aurelia_dialog_1 = require("aurelia-dialog");
var dayjs = require("dayjs");
var utc = require("dayjs/plugin/utc");
var flatpickr_1 = require("flatpickr");
var PublishTemplate = /** @class */ (function () {
    function PublishTemplate(DialogController) {
        this.controller = DialogController;
        dayjs.extend(utc);
    }
    Object.defineProperty(PublishTemplate.prototype, "disablePublishButton", {
        get: function () {
            switch (this.publishType) {
                case 'now':
                    return false;
                case 'later':
                    if (this.publishDate)
                        return false;
                    return true;
                default:
                    return true;
            }
        },
        enumerable: false,
        configurable: true
    });
    PublishTemplate.prototype.activate = function (model) {
        this.endorse = model.endorse;
    };
    PublishTemplate.prototype.bind = function () {
        var now = dayjs().format('YYYY-MM-DDTHH:mm');
        this.datePicker = (0, flatpickr_1.default)(this.publishDatePickerElement, {
            altInput: true,
            dateFormat: 'Y-m-d H:i',
            enableTime: true,
            minDate: now
        });
    };
    PublishTemplate.prototype.setPublishType = function (publishType) {
        this.publishType = publishType;
    };
    PublishTemplate.prototype.publishTemplate = function () {
        var data = {
            endorse: this.endorse,
            publishDate: null
        };
        // convert local time to UTC
        if (this.publishType === 'later')
            data.publishDate = dayjs(this.publishDate).utc().format('YYYY-MM-DD HH:mm');
        this.controller.ok(data);
    };
    __decorate([
        (0, aurelia_framework_1.computedFrom)('publishType', 'publishDate'),
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], PublishTemplate.prototype, "disablePublishButton", null);
    PublishTemplate = __decorate([
        (aurelia_framework_1.autoinject),
        __metadata("design:paramtypes", [aurelia_dialog_1.DialogController])
    ], PublishTemplate);
    return PublishTemplate;
}());
exports.PublishTemplate = PublishTemplate;
