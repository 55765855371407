"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Configuration = void 0;
var aurelia_plugins_cookies_1 = require("aurelia-plugins-cookies");
var Configuration = /** @class */ (function () {
    function Configuration() {
        this.home = true;
    }
    Configuration.getApplicationName = function () {
        return Configuration.appName;
    };
    Configuration.getAppURI = function () {
        if (WEBPACK_ENV === 'dev' || WEBPACK_ENV === 'local') {
            Configuration.appURI = 'https://radreportdev.rsna.org';
        }
        else if (WEBPACK_ENV === 'stage') {
            Configuration.appURI = 'https://radreportstage.rsna.org';
        }
        return Configuration.appURI;
    };
    Configuration.getBaseURI = function (basepath) {
        if (basepath === void 0) { basepath = 'radreport/v1'; }
        if (WEBPACK_ENV === 'dev' || WEBPACK_ENV === 'local') {
            Configuration.baseURI = 'https://api3dev.rsna.org';
        }
        else if (WEBPACK_ENV === 'stage') {
            Configuration.baseURI = 'https://api3stage.rsna.org';
        }
        return Configuration.baseURI + '/' + basepath;
    };
    Configuration.getTrexURI = function () {
        if (WEBPACK_ENV === 'dev' || WEBPACK_ENV === 'local' || WEBPACK_ENV === 'stage') {
            Configuration.trexLink = 'https://t-rexstage.rsna.org/worklist.jsp';
        }
        else {
            Configuration.trexLink = 'https://t-rex.rsna.org/worklist.jsp';
        }
        return Configuration.trexLink;
    };
    Configuration.getSingleSignOn = function () {
        var sso = aurelia_plugins_cookies_1.Cookies.get('SSO');
        // Only encode if sso is present. Otherwise
        // will return null, which evals to both true
        // and false (it's undefined, actually).
        if (!!sso) {
            return encodeURIComponent(aurelia_plugins_cookies_1.Cookies.get('SSO'));
        }
        else {
            return sso;
        }
    };
    Configuration.getHttp = function (http) {
        // encodeURI header value see https://www.owasp.org/index.php/Injection_Theory
        return http.configure(function (config) {
            config
                .useStandardConfiguration()
                .withBaseUrl(Configuration.getBaseURI())
                .withDefaults({
                headers: {
                    SSO: Configuration.getSingleSignOn(), // 'uW%2Bxdh3SDsHvOxcW%2BU1oVA%3D%3D',
                },
            });
        });
    };
    // Init for production
    Configuration.appName = 'RadReport';
    Configuration.appURI = 'https://portfolio.rsna.org';
    Configuration.baseURI = 'https://api3.rsna.org';
    Configuration.basePath = 'radreport/v1';
    Configuration.updateProfileLink = 'https://www2.rsna.org/timssnet/demographics/tnt_cusupdate.cfm';
    Configuration.loginLink = 'https://www2.rsna.org/timssnet/login/caslogin.cfm?referrer_path=' + Configuration.getAppURI();
    Configuration.logoutLink = 'https://www2.rsna.org/timssnet/reset.cfm';
    Configuration.trexLink = 'https://t-rex.rsna.org/worklist.jsp';
    return Configuration;
}());
exports.Configuration = Configuration;
