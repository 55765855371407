"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Metrics = void 0;
var metric_service_1 = require("./../../services/metric-service");
var filter_state_1 = require("../../state/filter-state");
var aurelia_framework_1 = require("aurelia-framework");
var d3 = require("d3");
var Metrics = /** @class */ (function () {
    function Metrics(FilterState, MetricsService) {
        this.filterstate = FilterState;
        this.filterstate.home = false;
        this.metricservice = MetricsService;
    }
    Metrics.prototype.attached = function () {
        this.getMetrics();
    };
    Metrics.prototype.getMetrics = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.metricservice.getMetrics()];
                    case 1:
                        _a.metrics = _b.sent();
                        this.loadChart();
                        return [2 /*return*/];
                }
            });
        });
    };
    Metrics.prototype.loadChart = function () {
        var data = [];
        //array to map color to specialty
        var colorDomain = [];
        for (var i = 0; i < 10; i++) {
            data.push({ label: this.metrics[1][i]['specialtyCode'],
                count: Number(this.metrics[1][i]['count']),
                fullName: this.metrics[1][i]['name']
            });
            colorDomain.push(this.metrics[1][i]['specialtyCode']);
        }
        var w = document.getElementById('chartContainer').clientWidth;
        var h = document.getElementById('chartContainer').clientHeight;
        var margin = { top: 20, right: 20, bottom: 30, left: 40 }, width = w - margin.left - margin.right, height = h - margin.top - margin.bottom;
        // set the ranges
        var x = d3.scaleBand()
            .range([0, width])
            .padding(0.1);
        var y = d3.scaleLinear()
            .range([height, 0]);
        // append the svg object to the body of the page
        // append a 'group' element to 'svg'
        // moves the 'group' element to the top left margin
        var svg = d3.select("#chartContainer").append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        // Scale the range of the data in the domains
        x.domain(data.map(function (d) { return d.label; }));
        y.domain([0, d3.max(data, function (d) { return d.count; })]);
        var color = d3.scaleOrdinal(d3.schemeCategory10).domain(colorDomain);
        // append the rectangles for the bar chart
        svg.selectAll(".bar")
            .data(data)
            .enter().append("rect")
            .attr("class", "bar")
            .attr("x", function (d) { return x(d.label); })
            .attr("width", x.bandwidth())
            .attr("y", function (d) { return y(d.count); })
            .attr("height", function (d) { return height - y(d.count); })
            .style("fill", function (d) { return color(d.label); })
            .on("mouseover", function (d) {
            var xPosition = parseFloat(d3.select(this).attr("x")) + x.bandwidth() / 2;
            var yPosition = parseFloat(d3.select(this).attr("y")) / 2 + height / 2;
            d3.select("#tooltip")
                .style("left", xPosition + "px")
                .style("top", yPosition + "px")
                .select("#value")
                .text(d.count);
            d3.select("#tooltip")
                .select("#spec")
                .text(d.fullName);
            d3.select("#tooltip").classed("hidden", false);
        })
            .on("mouseout", function () {
            d3.select("#tooltip").classed("hidden", true);
        });
        // add the x Axis
        svg.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x));
        // add the y Axis
        svg.append("g")
            .call(d3.axisLeft(y));
    };
    Metrics = __decorate([
        aurelia_framework_1.autoinject,
        __metadata("design:paramtypes", [filter_state_1.FilterState, metric_service_1.MetricsService])
    ], Metrics);
    return Metrics;
}());
exports.Metrics = Metrics;
