"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcedureFormatterValueConverter = void 0;
var ProcedureFormatterValueConverter = /** @class */ (function () {
    function ProcedureFormatterValueConverter() {
    }
    ProcedureFormatterValueConverter.prototype.toView = function (procedure) {
        switch (procedure.source) {
            case 'RADLEX_PLAYBOOK':
                return "RPID".concat(procedure.procedure_id, " ").concat(procedure.procedure_name);
            case 'LOINC':
                return "".concat(procedure.procedure_name, " <a href=\"https://loinc.org/").concat(procedure.procedure_id, "\" target=\"_blank\"><i class=\"fa fa-external-link\"></i></a>");
            default:
                return "";
        }
        return procedure;
    };
    return ProcedureFormatterValueConverter;
}());
exports.ProcedureFormatterValueConverter = ProcedureFormatterValueConverter;
