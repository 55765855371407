"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateTables = void 0;
var aurelia_event_aggregator_1 = require("aurelia-event-aggregator");
var aurelia_framework_1 = require("aurelia-framework");
var filter_state_1 = require("../../state/filter-state");
var aurelia_router_1 = require("aurelia-router");
var aurelia_store_1 = require("aurelia-store");
var operators_1 = require("rxjs/operators");
var aurelia_dialog_1 = require("aurelia-dialog");
var template_1 = require("store/actions/template");
var review_service_1 = require("services/review-service");
var template_service_1 = require("../../services/template-service");
var user_service_1 = require("services/user-service");
var reviewer_updates_modal_1 = require("../modals/reviewer-updates-modal/reviewer-updates-modal");
var TemplateTables = /** @class */ (function () {
    function TemplateTables(TemplateService, EventAggregator, dialogService, userservice, FilterState, Router, reviewservice) {
        this.dialogService = dialogService;
        this.userservice = userservice;
        this.reviewservice = reviewservice;
        this.nodebounce = false;
        this.debounceTime = 500;
        this.peerInfo = "\"TLAP Endorsed\" reporting templates meet the set of criteria defined by the Template Library Advisory Panel (TLAP), a team of volunteer members of RSNA and ESR.";
        this.pageSize = 20;
        this.currentFilter = '';
        this.searchTerm = '';
        this.templates = [];
        this.pageArr = [];
        this.pageNum = 1;
        this.download = [];
        this.sortData = {
            header: 'views',
            order: 'desc'
        };
        //var to stop "No templates returned" message to appear before table data is ready
        this.done = false;
        this.searching = false;
        this.updatingReviewers = false;
        this.loadingTemplates = false;
        this.templateservice = TemplateService;
        this.ea = EventAggregator;
        this.filterstate = FilterState;
        this.dialogService = dialogService;
        this.router = Router;
        this.subscribe();
    }
    TemplateTables.prototype.attached = function () {
        return __awaiter(this, void 0, void 0, function () {
            var parentName, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        parentName = this.parent.constructor.name;
                        if (!(parentName === 'ManageReviewers')) return [3 /*break*/, 2];
                        this.updatingReviewers = true;
                        _a = this;
                        return [4 /*yield*/, this.getReviewers()];
                    case 1:
                        _a.reviewerList = _b.sent();
                        this.updatingReviewers = false;
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.getTemplates()];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4:
                        $('[data-toggle="tooltip"]').tooltip();
                        return [2 /*return*/];
                }
            });
        });
    };
    TemplateTables.prototype.detached = function () {
        $('[data-toggle="tooltip"]').tooltip('dispose');
        this.filterSubscription.dispose();
    };
    TemplateTables.prototype.bind = function () {
        this.filterobj = this.filterstate.allFilters;
        if (this.nodebounce) {
            this.debounceTime = 0;
        }
    };
    // this is one way to reference a parent's props from within a child <compose>
    // element's view-model
    // other way: https://github.com/aurelia/framework/issues/242
    TemplateTables.prototype.created = function (owningView, myView) {
        this.parent = owningView.bindingContext;
    };
    //use parents funuction to get templates to populate table
    TemplateTables.prototype.getTemplates = function () {
        return __awaiter(this, void 0, void 0, function () {
            var filters, templateObj;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loadingTemplates = true;
                        this.download = [];
                        filters = this.getSelectedFilters();
                        return [4 /*yield*/, this.parent.getTableTemplates({ filters: filters, approved: this.filterobj.approved, search: this.filterobj.search })];
                    case 1:
                        templateObj = _a.sent();
                        if (templateObj) {
                            this.populateTable(templateObj);
                            this.pagination();
                        }
                        this.loadingTemplates = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    TemplateTables.prototype.getReviewers = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.reviewservice.getReviewers()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    //on search go through each filter and check if they are checked
    //todo: make this not bad
    TemplateTables.prototype.getSelectedFilters = function () {
        var filterArrays = {
            specialty: [],
            modality: [],
            bodyRegion: [],
            language: [],
            organization: [],
        };
        if (this.filterobj['sidebar'] != '') {
            filterArrays['specialty'].push(this.filterobj['sidebar']);
        }
        else {
            for (var filter in this.filterobj) {
                if (filter != 'search') {
                    for (var i in this.filterobj[filter]) {
                        if (this.filterobj[filter][i].checked == true) {
                            this.filterobj[filter][i].selected = true;
                            //STANDARDIZE FILTER NAMES RETURNED?
                            filterArrays[filter].push(this.filterobj[filter][i].code);
                        }
                        else {
                            this.filterobj[filter][i].selected = false;
                        }
                    }
                }
            }
        }
        return filterArrays;
    };
    //after data is returned set the table variables
    TemplateTables.prototype.populateTable = function (templateObj) {
        this.templates = templateObj;
        this.templateCount = templateObj.length;
        this.done = true;
    };
    //this function is called from published functions around the application
    TemplateTables.prototype.subscribe = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.filterSubscription = this.ea.subscribe("filters", function (response) {
                    //if(this.filterstate.home != "home"){ 
                    _this.router.navigate('#/home');
                    //}
                    _this.pageNum = 1;
                    _this.getTemplates();
                    (0, aurelia_store_1.dispatchify)(template_1.defocusTemplate)();
                });
                return [2 /*return*/];
            });
        });
    };
    //math for setting pages
    TemplateTables.prototype.pagination = function () {
        this.totalPages = Math.ceil(this.templateCount / this.pageSize);
        var i = 1;
        var j = this.totalPages;
        if (this.totalPages > 10) {
            if (this.pageNum > 5) {
                i = this.pageNum - 4;
            }
            if (this.pageNum < j - 5) {
                j = 9 + i;
            }
            else {
                i = j - 9;
            }
        }
        this.pageArr = [];
        while (i <= j) {
            this.pageArr.push(i);
            i++;
        }
    };
    TemplateTables.prototype.headerSort = function (header, order) {
        if (order === void 0) { order = 'asc'; }
        //if header is same a current header, flip asc to desc / desc to asc
        if (header == this.parent.sortData.header) {
            if (this.parent.sortData.order == 'desc') {
                this.parent.sortData.order = 'asc';
            }
            else {
                this.parent.sortData.order = 'desc';
            }
        }
        else {
            this.parent.sortData.header = header;
            this.parent.sortData.order = order;
        }
        this.pageNum = 1;
        this.getTemplates();
    };
    TemplateTables.prototype.focusTemplate = function (template, edit) {
        if (edit === void 0) { edit = false; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.router.navigate("#/".concat(this.state.tablename, "/").concat(template.template_id, "/").concat(template.created).concat(edit ? "?edit=true" : ""));
                return [2 /*return*/];
            });
        });
    };
    TemplateTables.prototype.submitForReview = function (template) {
        this.reviewservice.markAsReviewable(template.template_id);
        template.reviewInProgress = '1';
        $('[data-toggle="tooltip"]').tooltip();
    };
    TemplateTables.prototype.downloadAll = function () {
        return __awaiter(this, void 0, void 0, function () {
            var checkbox, template;
            return __generator(this, function (_a) {
                checkbox = document.getElementById("dlAll");
                if (checkbox.checked) {
                    for (template in this.templates) {
                        this.download.push(this.templates[template]);
                    }
                }
                else {
                    this.download = [];
                }
                return [2 /*return*/];
            });
        });
    };
    TemplateTables.prototype.toSubmit = function () {
        this.router.navigate('#/submit/');
    };
    TemplateTables.prototype.searchUsers = function (nameSearch) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(nameSearch.length >= 3)) return [3 /*break*/, 2];
                        this.searching = true;
                        this.userResults = [];
                        _a = this;
                        return [4 /*yield*/, this.userservice.getUsers(nameSearch)];
                    case 1:
                        _a.userResults = _b.sent();
                        this.searchResultsDisplayed = true;
                        return [3 /*break*/, 3];
                    case 2:
                        // do nothing, just wait til they input enough text to fire away
                        this.searchResultsDisplayed = false;
                        _b.label = 3;
                    case 3:
                        this.searching = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    TemplateTables.prototype.addReviewer = function (userIn) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, addReviewerData, found;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.updatingReviewers = true;
                        _a = this;
                        return [4 /*yield*/, this.getReviewers()];
                    case 1:
                        _a.reviewerList = _b.sent();
                        found = false;
                        // loop through the reviewer list and check if any IDs match
                        this.reviewerList.forEach(function (reviewer) {
                            if (userIn.id === reviewer.id) {
                                found = true;
                            }
                        });
                        if (!found) {
                            // we'll add them
                            addReviewerData = {
                                user: userIn,
                                action: 'add_reviewer',
                                found: false,
                            };
                            this.dialogService.open({ viewModel: reviewer_updates_modal_1.ReviewerUpdatesModal, model: addReviewerData, lock: false }).whenClosed(function (response) { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            if (!!response.wasCancelled) return [3 /*break*/, 2];
                                            // update list after the user adds the dialog is closed/ confirmed
                                            this.updatingReviewers = true;
                                            _a = this;
                                            return [4 /*yield*/, this.getReviewers()];
                                        case 1:
                                            _a.reviewerList = _b.sent();
                                            this.updatingReviewers = false;
                                            _b.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); });
                        }
                        else {
                            // show message stating we wopt add them because theyre already a reviewer
                            addReviewerData = {
                                user: userIn,
                                action: 'add_reviewer',
                                found: true,
                            };
                            this.dialogService.open({ viewModel: reviewer_updates_modal_1.ReviewerUpdatesModal, model: addReviewerData, lock: false });
                        }
                        this.updatingReviewers = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    TemplateTables.prototype.removeReviewer = function (userIn) {
        return __awaiter(this, void 0, void 0, function () {
            var removeReviewerData;
            var _this = this;
            return __generator(this, function (_a) {
                removeReviewerData = {
                    user: userIn,
                    action: 'remove_reviewer',
                };
                this.dialogService.open({ viewModel: reviewer_updates_modal_1.ReviewerUpdatesModal, model: removeReviewerData, lock: false }).whenClosed(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                if (!!response.wasCancelled) return [3 /*break*/, 2];
                                // toggle loading icon while sending request for reviewers after updating list
                                this.updatingReviewers = true;
                                _a = this;
                                return [4 /*yield*/, this.getReviewers()];
                            case 1:
                                _a.reviewerList = _b.sent();
                                this.updatingReviewers = false;
                                _b.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    __decorate([
        aurelia_framework_1.bindable,
        __metadata("design:type", Object)
    ], TemplateTables.prototype, "filterobj", void 0);
    __decorate([
        aurelia_framework_1.bindable,
        __metadata("design:type", Object)
    ], TemplateTables.prototype, "gettemplates", void 0);
    __decorate([
        aurelia_framework_1.bindable,
        __metadata("design:type", Object)
    ], TemplateTables.prototype, "nodebounce", void 0);
    TemplateTables = __decorate([
        (aurelia_framework_1.autoinject),
        (0, aurelia_store_1.connectTo)(function (store) { return store.state.pipe((0, operators_1.pluck)('template')); }),
        __metadata("design:paramtypes", [template_service_1.TemplateService, aurelia_event_aggregator_1.EventAggregator, aurelia_dialog_1.DialogService, user_service_1.UserService, filter_state_1.FilterState, aurelia_router_1.Router, review_service_1.ReviewService])
    ], TemplateTables);
    return TemplateTables;
}());
exports.TemplateTables = TemplateTables;
