"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BooleanToStringValueConverter = void 0;
var BooleanToStringValueConverter = /** @class */ (function () {
    function BooleanToStringValueConverter() {
    }
    BooleanToStringValueConverter.prototype.toView = function (value) {
        if (value == 0) {
            value = "No";
        }
        else if (value == 1) {
            value = "Yes";
        }
        else {
            value = "N/A";
        }
        return value;
    };
    return BooleanToStringValueConverter;
}());
exports.BooleanToStringValueConverter = BooleanToStringValueConverter;
