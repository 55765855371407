"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.configure = void 0;
var aurelia_pal_1 = require("aurelia-pal");
function configure(config) {
    config.globalResources([
        aurelia_pal_1.PLATFORM.moduleName('./home/home'),
        aurelia_pal_1.PLATFORM.moduleName('./home/spec-filters/spec-filters'),
        aurelia_pal_1.PLATFORM.moduleName('./about/about'),
        aurelia_pal_1.PLATFORM.moduleName('./loading-spinner/loading-spinner'),
        aurelia_pal_1.PLATFORM.moduleName('./metrics/metrics'),
        aurelia_pal_1.PLATFORM.moduleName('./modals/adv-search/adv-search'),
        aurelia_pal_1.PLATFORM.moduleName('./modals/login-prompt/login-prompt'),
        aurelia_pal_1.PLATFORM.moduleName('./modals/download-prompt/download-prompt'),
        aurelia_pal_1.PLATFORM.moduleName('./template/template'),
        aurelia_pal_1.PLATFORM.moduleName('./navMenu/navMenu'),
        aurelia_pal_1.PLATFORM.moduleName('./developer/developers'),
        aurelia_pal_1.PLATFORM.moduleName('./navMenu/navbar/nav-bar'),
        aurelia_pal_1.PLATFORM.moduleName('./navMenu/footer/footer'),
        aurelia_pal_1.PLATFORM.moduleName('./navMenu/search/search'),
        aurelia_pal_1.PLATFORM.moduleName('./submit/upload-template/upload-template'),
        aurelia_pal_1.PLATFORM.moduleName('./submit/submit-template/submit-template'),
        aurelia_pal_1.PLATFORM.moduleName('./profile/profile'),
        aurelia_pal_1.PLATFORM.moduleName('./profile/profile-menu/profile-menu'),
        aurelia_pal_1.PLATFORM.moduleName('./profile/my-templates/my-templates'),
        aurelia_pal_1.PLATFORM.moduleName('./profile/my-profile/my-profile'),
        aurelia_pal_1.PLATFORM.moduleName('./profile/favorites/favorites'),
        aurelia_pal_1.PLATFORM.moduleName('./profile/chair-assign/chair-assign'),
        aurelia_pal_1.PLATFORM.moduleName('./review/review-template/review-template'),
        aurelia_pal_1.PLATFORM.moduleName('./profile/manage-reviewers/manage-reviewers'),
        aurelia_pal_1.PLATFORM.moduleName('./profile/my-assignments/my-assignments'),
        aurelia_pal_1.PLATFORM.moduleName('./review/assign-templates/assign-templates'),
        aurelia_pal_1.PLATFORM.moduleName('./review/approve-templates/approve-templates'),
        aurelia_pal_1.PLATFORM.moduleName('./profile/endorse-templates/endorse-templates'),
        aurelia_pal_1.PLATFORM.moduleName('./template-details/template-details'),
        aurelia_pal_1.PLATFORM.moduleName('./template-tables/template-buttons/template-buttons'),
        aurelia_pal_1.PLATFORM.moduleName('./comment-section/comment-section'),
        aurelia_pal_1.PLATFORM.moduleName('./pagination/pagination'),
        aurelia_pal_1.PLATFORM.moduleName('./autocomplete/autocomplete'),
        //value converters
        aurelia_pal_1.PLATFORM.moduleName("../value-converters/date-converter"),
        aurelia_pal_1.PLATFORM.moduleName("../value-converters/limit-to"),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/boolean-to-string'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/format-array-string'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/summarize-endorsement'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/specs-sort'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/table-limit'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/template-amount'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/version-number'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/procedure-formatter'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/show-more'),
    ]);
}
exports.configure = configure;
