"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateConverterValueConverter = void 0;
var moment = require("moment");
var DateConverterValueConverter = /** @class */ (function () {
    function DateConverterValueConverter() {
    }
    DateConverterValueConverter.prototype.toView = function (value) {
        return moment(value).format('M/D/YYYY');
    };
    return DateConverterValueConverter;
}());
exports.DateConverterValueConverter = DateConverterValueConverter;
