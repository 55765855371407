"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavMenu = void 0;
var aurelia_framework_1 = require("aurelia-framework");
var aurelia_router_1 = require("aurelia-router");
var NavMenu = /** @class */ (function () {
    function NavMenu(router, bindingEngine) {
        var _this = this;
        this.router = router;
        this.bindingEngine = bindingEngine;
        //check if route is home for hero image
        var sub = this.bindingEngine.propertyObserver(this.router, 'currentInstruction').subscribe(function (currentInstruction) {
            if (currentInstruction.fragment.includes("home") || currentInstruction.fragment == "/") {
                _this.home = true;
            }
            else {
                _this.home = false;
            }
        });
    }
    NavMenu = __decorate([
        (aurelia_framework_1.autoinject),
        __metadata("design:paramtypes", [aurelia_router_1.Router, aurelia_framework_1.BindingEngine])
    ], NavMenu);
    return NavMenu;
}());
exports.NavMenu = NavMenu;
