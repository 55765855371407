"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Developer = void 0;
var filter_state_1 = require("../../state/filter-state");
var aurelia_framework_1 = require("aurelia-framework");
var config_1 = require("../../config");
var Developer = /** @class */ (function () {
    function Developer(FilterState) {
        this.filterstate = FilterState;
        this.filterstate.home = false;
    }
    Developer.prototype.bind = function () {
        this.base_url = config_1.Configuration.getBaseURI();
    };
    Developer = __decorate([
        aurelia_framework_1.autoinject,
        __metadata("design:paramtypes", [filter_state_1.FilterState])
    ], Developer);
    return Developer;
}());
exports.Developer = Developer;
