"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getToken = void 0;
function getToken(name) {
    try {
        name += "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    catch (error) {
        return false;
    }
}
exports.getToken = getToken;
