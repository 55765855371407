"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowMoreValueConverter = void 0;
var ShowMoreValueConverter = /** @class */ (function () {
    function ShowMoreValueConverter() {
    }
    ShowMoreValueConverter.prototype.toView = function (dataArray, limit) {
        //if a limit is set, only show that many values in a array
        if (dataArray && limit) {
            return dataArray.slice(0, limit);
        }
        else {
            return dataArray;
        }
    };
    return ShowMoreValueConverter;
}());
exports.ShowMoreValueConverter = ShowMoreValueConverter;
