"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.App = void 0;
var filter_state_1 = require("./state/filter-state");
var aurelia_framework_1 = require("aurelia-framework");
/**
 * RadReport 2.0
 *
 * @author Michael Crabtree
 * @author Derrick Lottich
 *
 * RadReport v1.0 :https://web.archive.org/web/20160221185902/http://radreport.org/
 */
var aurelia_router_1 = require("aurelia-router");
var aurelia_pal_1 = require("aurelia-pal");
var config_1 = require("./config");
var route_scroll_page_1 = require("./utils/route-scroll-page");
var aurelia_event_aggregator_1 = require("aurelia-event-aggregator");
var aurelia_store_1 = require("aurelia-store");
var store_1 = require("./store/store");
var auth_1 = require("store/actions/auth");
var token_1 = require("./utils/token");
var App = /** @class */ (function () {
    function App(FilterState, router, ea) {
        this.router = router;
        this.ea = ea;
        this.showNav = true;
        this.filterstate = FilterState;
        //register actions for local storage middleware
        store_1.default.registerAction('Rehydrate', aurelia_store_1.rehydrateFromLocalStorage);
        store_1.default.registerMiddleware(aurelia_store_1.localStorageMiddleware, aurelia_store_1.MiddlewarePlacement.After, { key: 'D593FDC3C6DD4D0320A7B1F5BF419BF2' });
        //get data in local storage and put it in state
        store_1.default.dispatch(aurelia_store_1.rehydrateFromLocalStorage, 'D593FDC3C6DD4D0320A7B1F5BF419BF2');
        var token = (0, token_1.getToken)("TOKEN");
        if (!token) {
            (0, aurelia_store_1.dispatchify)(auth_1.logout)();
        }
    }
    App.prototype.bind = function () {
        var _this = this;
        this.ea.subscribe('router:navigation:complete', function () {
            _this.showNav = _this.router.currentInstruction.config.showNav;
        });
    };
    App.prototype.unbind = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                localStorage.clear();
                return [2 /*return*/];
            });
        });
    };
    App.prototype.created = function () {
        return __awaiter(this, void 0, void 0, function () {
            var script;
            return __generator(this, function (_a) {
                script = document.createElement('script');
                script.src = 'https://cdn.rsna.org/shared-javascript/src/js/cookiePolicy.js';
                document.body.appendChild(script);
                return [2 /*return*/];
            });
        });
    };
    App.prototype.configureRouter = function (config, router) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                config.title = config_1.Configuration.getApplicationName();
                config.options.pushState = true;
                config.options.root = '/';
                config.addPostRenderStep(route_scroll_page_1.RouteScrollPage);
                config.map([
                    {
                        route: ['home', 'home/:id?/:version?'],
                        //Hotloading 
                        moduleId: aurelia_pal_1.PLATFORM.moduleName('./components/home/home', 'home'),
                        title: 'Home',
                        name: 'home',
                        settings: {
                            scrollToTop: true,
                        },
                        showNav: true,
                    }, {
                        route: 'about/:page?',
                        name: 'about',
                        moduleId: aurelia_pal_1.PLATFORM.moduleName('./components/about/about', "about"),
                        settings: {
                            scrollToTop: true,
                        },
                        showNav: true,
                    }, {
                        route: 'metrics',
                        name: 'metrics',
                        moduleId: aurelia_pal_1.PLATFORM.moduleName('./components/metrics/metrics', 'metrics'),
                        settings: {
                            scrollToTop: true,
                        },
                        showNav: true,
                    }, {
                        route: 'developers',
                        name: 'developers',
                        moduleId: aurelia_pal_1.PLATFORM.moduleName('./components/developer/developers', 'developers'),
                        settings: {
                            scrollToTop: true,
                        },
                        showNav: true,
                    }, {
                        route: 'submit',
                        name: 'submit',
                        moduleId: aurelia_pal_1.PLATFORM.moduleName('./components/submit/submit-template/submit-template', 'submit'),
                        settings: {
                            scrollToTop: true,
                        },
                        showNav: true,
                    }, {
                        route: [
                            'profile',
                            'profile/:tab/:id?/:version?'
                        ],
                        name: 'profile',
                        moduleId: aurelia_pal_1.PLATFORM.moduleName('./components/profile/profile', 'profile'),
                        settings: {
                            scrollToTop: true,
                        },
                        showNav: true,
                        activationStrategy: aurelia_router_1.activationStrategy.replace
                    }, {
                        route: ['template/:id/:version'],
                        //Hotloading 
                        moduleId: aurelia_pal_1.PLATFORM.moduleName('./components/template/template', 'template'),
                        title: 'Template',
                        name: 'template',
                        settings: {
                            scrollToTop: true,
                        },
                        showNav: false,
                    },
                ]);
                config.mapUnknownRoutes(aurelia_pal_1.PLATFORM.moduleName('./components/home/home'));
                this.router = router;
                return [2 /*return*/];
            });
        });
    };
    App = __decorate([
        aurelia_framework_1.autoinject,
        (0, aurelia_store_1.connectTo)(),
        __metadata("design:paramtypes", [filter_state_1.FilterState, aurelia_router_1.Router, aurelia_event_aggregator_1.EventAggregator])
    ], App);
    return App;
}());
exports.App = App;
