"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadPrompt = void 0;
var download_service_1 = require("./../../../services/download-service");
var aurelia_event_aggregator_1 = require("aurelia-event-aggregator");
var aurelia_framework_1 = require("aurelia-framework");
var aurelia_dialog_1 = require("aurelia-dialog");
var DownloadPrompt = /** @class */ (function () {
    function DownloadPrompt(EventAggregator, DownloadService, DialogController) {
        this.approvedFilter = false;
        this.selectedIds = [];
        this.ea = EventAggregator;
        this.downloadservice = DownloadService;
        this.controller = DialogController;
    }
    DownloadPrompt.prototype.activate = function (model) {
        this.download = model;
        model = [];
        for (var temp in this.download) {
            this.selectedIds.push(this.download[temp]['template_version']);
        }
    };
    DownloadPrompt.prototype.downloadFiles = function () {
        var _this = this;
        var fileName;
        //zip if more than one template in downloads
        if (this.selectedIds.length > 1) {
            fileName = "RSNA-RAD-REPORT.zip";
        }
        else {
            //if its a single template set the title as the file name and download specific format
            var singleTemplateDetails = this.download.filter(function (template) {
                return template.template_version == _this.selectedIds[0];
            });
            fileName = singleTemplateDetails[0].title + ".".concat(this.format);
        }
        this.downloadservice.downloadTemplates(this.selectedIds, this.format, fileName);
        this.controller.ok();
    };
    DownloadPrompt.prototype.approvedFilterChange = function () {
        this.approvedFilter = !this.approvedFilter;
        this.selectedIds = [];
        //add all tlap approved templates to download queue
        if (this.approvedFilter) {
            for (var template in this.download) {
                if (this.download[template].TLAP_Approved == 1) {
                    this.selectedIds.push(this.download[template]['template_version']);
                }
            }
            //add all templates to download
        }
        else {
            for (var template in this.download) {
                this.selectedIds.push(this.download[template]['template_version']);
            }
        }
    };
    DownloadPrompt = __decorate([
        (aurelia_framework_1.autoinject),
        __metadata("design:paramtypes", [aurelia_event_aggregator_1.EventAggregator, download_service_1.DownloadService, aurelia_dialog_1.DialogController])
    ], DownloadPrompt);
    return DownloadPrompt;
}());
exports.DownloadPrompt = DownloadPrompt;
