"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadingSpinner = void 0;
// node_modules
var aurelia_framework_1 = require("aurelia-framework");
var aurelia_event_aggregator_1 = require("aurelia-event-aggregator");
var LoadingSpinner = /** @class */ (function () {
    function LoadingSpinner(eventAggregator) {
        // variable two-way bound to parent component, allowing either side to change status
        this.active = false;
        // progress bar variables
        this.progressBar = false;
        this.progressDistance = 0;
        this.text = '';
        this.ea = eventAggregator;
    }
    LoadingSpinner.prototype.attached = function () {
        var _this = this;
        // TODO: Make sure loading spinner is only listening to events it needs to
        var startEventType;
        var endEventType;
        startEventType = 'loading';
        endEventType = 'loaded';
        if (this.id) {
            startEventType += ':' + this.id;
            endEventType += ':' + this.id;
        }
        this.onSubscription = this.ea.subscribe(startEventType, function (resp) {
            if (resp === void 0) { resp = { progress: false, text: '', distance: 0 }; }
            _this.active = true;
            if (resp.progress) {
                _this.progressBar = true;
                _this.progressDistance = resp.distance;
                document.getElementById('loading-spinner-progress-bar').style.width = "".concat(_this.progressDistance, "%");
            }
            if (resp.text) {
                _this.text = resp.text;
            }
        });
        this.offSubscription = this.ea.subscribe(endEventType, function (resp) {
            _this.active = false;
            _this.progressBar = false;
            _this.progressDistance = 0;
            _this.text = '';
        });
    };
    LoadingSpinner.prototype.detached = function () {
        this.onSubscription.dispose();
        this.offSubscription.dispose();
    };
    __decorate([
        aurelia_framework_1.bindable,
        __metadata("design:type", Object)
    ], LoadingSpinner.prototype, "active", void 0);
    __decorate([
        aurelia_framework_1.bindable,
        __metadata("design:type", String)
    ], LoadingSpinner.prototype, "id", void 0);
    LoadingSpinner = __decorate([
        (0, aurelia_framework_1.inject)(aurelia_event_aggregator_1.EventAggregator),
        __metadata("design:paramtypes", [aurelia_event_aggregator_1.EventAggregator])
    ], LoadingSpinner);
    return LoadingSpinner;
}());
exports.LoadingSpinner = LoadingSpinner;
